<template>
  <div class="ud-body">
    <a-card :bordered="false">
      <!-- 搜索表单 -->
      <a-form :model="where" :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }">
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="手机号:">
              <a-input v-model:value.trim="where.username" placeholder="请输入" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="角色:">
              <a-select allow-clear placeholder="请选择角色" v-model:value="where.roleId">
                <a-select-option v-for="item in roleList" :key="item.roleId" :value="item.roleId">
                  {{ item.roleName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24"> </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
              <a-space>
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <ud-pro-table ref="table" row-key="userId" :datasource="url" :columns="columns" :where="where"
        v-model:selection="selection" :scroll="{ x: 'max-content' }">
        <template #toolbar>
          <a-space>
          </a-space>
        </template>
        <template #nickname="{ record }">
          {{ record.nickname }}
        </template>
        <template #roles="{ record }">
          <a-tag v-for="(item, index) in record.roles" :key="index" color="blue">
            {{ item.roleName }}
          </a-tag>
        </template>
        <template #enable="{ text, record }">
          <a-switch :checked="text === 0" @change="(checked) => editEnabled(checked, record)" />
        </template>
        <template #action="{ record }">
          <a-space>
            <a @click="openEdit(record)">修改</a>
          </a-space>
        </template>
      </ud-pro-table>
    </a-card>
  </div>
  <!-- 编辑弹窗 -->
  <user-edit v-model:visible="showEdit" :data="current" @done="reload" />
  <!-- 导入弹窗 -->
  <user-import v-model:visible="showImport" @done="reload" />
</template>

<script>
import { createVNode } from 'vue'
import {
  ExclamationCircleOutlined
} from '@ant-design/icons-vue'
import UserEdit from './user-edit'

export default {
  name: 'SystemUser',
  components: {
    UserEdit
  },
  data() {
    return {
      // 表格数据接口
      url: '/sys/user/client',
      // 表格列配置
      columns: [
        {
          key: 'index',
          width: 48,
          customRender: ({ index }) => this.$refs.table.tableIndex + index
        },
        // dataIndex唯一的
        {
          title: '手机号',
          dataIndex: 'username',
          sorter: true
        },
        {
          title: '姓名',
          dataIndex: 'realName',
          sorter: true
        },
        {
          title: '身份证',
          dataIndex: 'idCard',
          sorter: true
        },
        {
          title: '呢称',
          dataIndex: 'nickname',
          sorter: true,
          slots: { customRender: 'nickname' }
        },
        {
          title: '性别',
          dataIndex: 'sex',
          sorter: true
        },
        {
          title: '角色',
          key: 'roles',
          slots: { customRender: 'roles' }
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          sorter: true,
          customRender: ({ text }) => this.$util.toDateString(text)
        },
        {
          title: '启用',
          dataIndex: 'enable',
          sorter: true,
          width: 90,
          align: 'center',
          slots: { customRender: 'enable' }
        },
        {
          title: '操作',
          key: 'action',
          width: 200,
          align: 'center',
          slots: { customRender: 'action' }
        }
      ],
      // 表格搜索条件
      where: {},
      // 表格选中数据
      selection: [],
      // 当前编辑数据
      current: null,
      // 是否显示编辑弹窗
      showEdit: false,
      // 是否显示用户导入弹窗
      showImport: false,
      roleList: []
    }
  },
  created() {
    this.queryRoles()
  },
  methods: {
    /* 搜索 */
    reload() {
      this.selection = []
      this.$refs.table.reload({ page: 1, where: this.where })
    },
    /*  重置搜索 */
    reset() {
      this.where = {}
      this.reload()
    },
    /* 删除单个 */
    remove(row) {
      const hide = this.$message.loading('请求中..', 0)
      this.$http
        .delete('/sys/user/' + row.userId)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            this.$message.success(res.data.msg)
            this.reload()
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
    },
    /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error('请至少选择一条数据')
        return
      }
      this.$confirm({
        title: '提示',
        content: '确定要删除选中的用户吗?',
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          this.$http
            .delete('/sys/user/batch', {
              data: this.selection.map((d) => d.userId)
            })
            .then((res) => {
              hide()
              if (res.data.code === 0) {
                this.$message.success(res.data.msg)
                this.reload()
              } else {
                this.$message.error(res.data.msg)
              }
            })
            .catch((e) => {
              hide()
              this.$message.error(e.message)
            })
        }
      })
    },
    /* 打开编辑弹窗 */
    openEdit(row) {
      this.current = row
      this.showEdit = true
    },
    /* 重置用户密码 */
    resetPsw(row) {
      this.$confirm({
        title: '提示',
        content: '确定要重置此用户的密码为"123456"吗?',
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          const formData = new FormData()
          formData.append('password', '123456')
          this.$http
            .put('/sys/user/psw/' + row.userId, formData)
            .then((res) => {
              hide()
              if (res.data.code === 0) {
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            })
            .catch((e) => {
              hide()
              this.$message.error(e.message)
            })
        }
      })
    },
    /* 修改用户状态 */
    editEnabled(checked, row) {
      const params = new FormData()
      params.append('enable', checked ? 0 : 1)
      this.$http
        .put('/sys/user/enable/' + row.userId, params)
        .then((res) => {
          if (res.data.code === 0) {
            row.enable = checked ? 0 : 1
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    queryRoles() {
      this.roleList = [
        {
          roleId: 44,
          roleCode: 'mp-weixin',
          roleName: '微信用户',
          comments: '微信用户'
        },
        {
          roleId: 47,
          roleCode: 'corp',
          roleName: '团长',
          comments: '团长'
        }
      ]
    }
  }
}
</script>

<style scoped>
</style>
